import { Spinner } from 'react-bootstrap';

export interface IAppProps {
}

export default function Loader (props: IAppProps) {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{height:"100vh"}}>
      <Spinner />
    </div>
  );
}
