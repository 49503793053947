import { _get, _getAuth, _post, _postAuth, _put, convertObjToQueryStr } from './apiMethods'

export const API_INITIAL = process.env.REACT_APP_API_URL


export const signIn = async (data:object) => {
    let url = API_INITIAL + '/admin/signin'
    return await _post(url, data)
}

export const signOut = async () => {
    let url = API_INITIAL + '/admin/logout'
    return await _post(url)
}

export const refreshAccessToken = async () => {
    try {
        let url = API_INITIAL + '/admin/token'
        let refreshToken = localStorage.getItem("refreshToken")
        const response : any = _post(url, { token : refreshToken });
        return response;
      } catch (error) {
        return null;
      }
}

export const signUp = async (data:object) => {
    let url = API_INITIAL + '/signup'
    return await _post(url, data)
}

export const forgetPassword = async (data:object) => {
    let url = API_INITIAL + '/admin/forgot_passward'
    return await _post(url, data)
}
export const verifyOTP = async (data:object) => {
    let url = API_INITIAL + '/admin/verification_otp'
    return await _post(url, data)
}
export const resendOTP = async (data:object) => {
    let url = API_INITIAL + '/admin/resend_otp'
    return await _post(url, data)
}
export const changePassword = async (data:object) => {
    let url = API_INITIAL + '/admin/change_password'
    return await _post(url, data)
}

export const getProfile = async () => {
    let url = API_INITIAL + '/admin/profile'
    return await _get(url)
}

export const redirectCoupon = async (data? : any) => {
    let url = API_INITIAL + '/admin/get-qr-code'
    if(data){
        let str = convertObjToQueryStr(data);
        url = url + str
    }
    return await _getAuth(url)
}
