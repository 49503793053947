import React, { useContext, Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Loader from "./Components/Loader";
import AuthContext from "./Contexts/AuthContext";
import GetUserType from "./Contexts/GetUserType";

// css
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/styles/style.css";
import "./Assets/styles/dashboard.css";
import RedirectCoupon from "./Pages/RedirectCoupon";

const MainLayout = lazy(() => import("./Layout/MainLayout"));
const DefaultLayout = lazy(() => import("./Layout/DefaultLayout"));

function App() {
  const { isAuthenticated } = useContext(AuthContext);
  const usertype = GetUserType();

  useEffect(() => {
    // for disabled mouse scroll on input field type number
    let windowCopy: any = window;
    window.addEventListener(
      "mousewheel",
      function (event) {
        if (windowCopy === "number") {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  }, []);

  // const location= useLocation()
  // console.log(location, "location")

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/user/coupon-redirect" element={<RedirectCoupon />} />
          {isAuthenticated ? (
            <>
              <Route path={`/${usertype}/*`} element={<MainLayout />} />
              <Route
                path={`*`}
                element={<Navigate replace to={`/${usertype}/dashboard`} />}
              />
            </>
          ) : isAuthenticated === false ? (
            <>
              <Route path={`/user/*`} element={<DefaultLayout />} />
              <Route path={`*`} element={<Navigate replace to="/user/" />} />
            </>
          ) : (
            <Route path={`*`} element={<Loader />} />
          )}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
