import axios, { AxiosInstance, AxiosResponse, AxiosError }from 'axios';
import { refreshAccessToken } from './Apis';

const axiosInstance : AxiosInstance = axios.create(
	{ baseURL: process.env.REACT_APP_API_URL
		// , withCredentials: true
	}
);

axiosInstance.interceptors.response.use(
	(response : AxiosResponse) => response?.data || response,
	async (error : any) => {
		const originalRequest : any = error.config;
		// console.log(error,'errorerrorerror')
		// If access token has expired, refresh it
		if (error?.response && error.response.status === 403 && !originalRequest._retry) {
		  originalRequest._retry = true;
		  let refreshTokenRes : any = null
		  if(error.response.data.message === 'Token Expired'){
			  refreshTokenRes = await refreshAccessToken();
			  if (refreshTokenRes.status === true) {
				localStorage.setItem("accessToken",refreshTokenRes.data.token)
				originalRequest.headers['Authorization'] = refreshTokenRes.data.token;
				return axiosInstance(originalRequest); // Retry the original request with the new token
			  }
		  }else if(error.response.data.message === 'Refresh Token Expired'){
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')
			window.location.reload()
		  }
		}
		return Promise.reject((error.response && error.response.data) || 'Something went wrong!')
	}
);

// axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default axiosInstance;
