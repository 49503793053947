import { useContext } from "react";
import AuthContext from "./AuthContext";

const GetUserType = () => {
  const { user } : { user : { user_type? : string } | null} = useContext(AuthContext);

    switch (user?.user_type || 'user') {
      case "SUPERADMIN": {
        return "admin";
      }
      case "ADMIN": {
        return "employee";
      }
      case "VENDOR": {
        return "vendor";
      }
      default: {
        return "user";
      }
    }
};

export default GetUserType;
