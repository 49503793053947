import { _get, _post, _put, _patch, _delete, _getAuth, convertObjToQueryStr } from './apiMethods'

export const API_INITIAL = process.env.REACT_APP_API_URL


// Roles------------------------------------------------------------

export const getRole = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/role_list'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/role_list/'+id 
    }
    return await _getAuth(url)
}

export const getSingleRole = async (data : any) => {
    let url = API_INITIAL + `/admin/role_list/${data.id}`
    return await _getAuth(url, data)
}


export const createRole = async (data:any) => {
    let url = API_INITIAL + '/admin/create_role'
    return await _post(url,data)
}

export const upadateRole = async (data:any) => {
    let url = API_INITIAL + `/admin/update_role/${data.id}`
    return await _put(url,data)
}

export const upadateRoleStatus = async (data:any) => {
    let url = API_INITIAL + '/admin/status_role'
    return await _post(url,data)
}



// Admin Api------------------------------------------------------------
export const getAdminList = async ({id}:{id?:any , params?:any}) => {
    
    let url = API_INITIAL + '/admin/admin_list'
    if(id){
        url = API_INITIAL + '/admin/admin_list/'+id 
    }
    return await _getAuth(url)
}

export const CreateSubAdmin = async (data:any) => {
    let url = API_INITIAL + '/admin/create'
    return await _post(url,data)
}

export const upadateSubAdmin = async (data:any , id:any) => {
    let url = API_INITIAL + '/admin/update/'+id
    return await _put(url,data)
}

export const getCollection = async () => {
    let url = API_INITIAL + '/admin/collection_list'
    return await _getAuth(url)
}

export const getPermission = async () => {
    let url = API_INITIAL + '/admin/permission_list'
    return await _getAuth(url)
}


// Vendors------------------------------------------------------------

export const getVendor = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/vendor_list'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/vendor_list/'+id 
    }
    return await _getAuth(url)
}

export const createVendor = async (data:any) => {
    let url = API_INITIAL + '/admin/create_vendor'
    return await _post(url,data)
}

export const upadateVendor = async (data:any , id:any) => {
    let url = API_INITIAL + '/admin/update/'+id
    return await _put(url,data)
}

export const getRiskLimit = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/risk-limits'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/risk-limits/'+id 
    }
    return await _getAuth(url)
}
// Brands --------------------------------------------------------------

export const getBrand = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/brand_list'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/brand_list/'+id 
    }
    return await _getAuth(url)
}

export const createBrand = async (data:any) => {
    let url = API_INITIAL + '/admin/create_brand'
    return await _post(url,data)
}

export const upadateBrandStatus = async (data:any) => {
    let url = API_INITIAL + '/admin/status_brand'
    return await _post(url,data)
}

// Channel----------------------------------------------------------------------------

export const getChannel = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/channel_list'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/channel_list/'+id 
    }
    return await _getAuth(url)
}

export const createChannel = async (data:any) => {
    let url = API_INITIAL + '/admin/create_channel'
    return await _post(url,data)
}

export const upadateChannelStatus = async (data:any) => {
    let url = API_INITIAL + '/admin/status_channel'
    return await _post(url,data)
}

// Vendor-----------------------------------------------------------------------------------

export const createVendorEmp = async (data:any) => {
    let url = API_INITIAL + '/admin/create_vendor'
    return await _post(url,data)
}

// Logs---------------------------------------------------------------------------------


export const getLogs = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/admin-logs'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/admin-logs/'+id 
    }
    return await _getAuth(url)
}

// Admin Api------------------------------------------------------------
export const getEmployee = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/admin_list'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/admin_list/'+id 
    }
    return await _getAuth(url)
}

// contact---------------------------------------------------------------

export const getContact = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/contact_list'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/contact_list/'+id 
    }
    return await _getAuth(url)
}

export const createContact = async (data:any) => {
    let url = API_INITIAL + '/admin/create_contact'
    return await _post(url,data)
}

export const upadateContactStatus = async (data:any) => {
    let url = API_INITIAL + '/admin/status_contact'
    return await _post(url,data)
}

// Help------------------------------------------------------------

export const getHelpList = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/contact_support/email_list'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/contact_support/email_list/'+id 
    }
    return await _getAuth(url)
}

// Dashbaord-------------------

export const getOrder = async () => {
    let url = API_INITIAL + '/admin/order_list'
    return await _getAuth(url)
}
export const getTopFiveCategory = async () => {
    let url = API_INITIAL + '/admin/top_five_category'
    return await _getAuth(url)
}
export const getCardDetail = async () => {
    let url = API_INITIAL + '/admin/card_dashboard'
    return await _getAuth(url)
}
export const getProfitableCategory = async () => {
    let url = API_INITIAL + '/admin/profitable_Category'
    return await _getAuth(url)
}
export const getSaleValue = async () => {
    let url = API_INITIAL + '/admin/sale_increased'
    return await _getAuth(url)
}
export const getReveueGraph = async (params:any) => {
    let str = convertObjToQueryStr(params)
    let url = API_INITIAL + '/admin/revenue_graph'
    url = url + str
    return await _getAuth(url)
}

export const getDashboardData = async () => {
    let url = API_INITIAL + '/admin/dashboard'
    return await _getAuth(url)
}


// Reports-------------------

export const getRegistrationReport = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/report/user-registration'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/report/user-registration/'+id 
    }
    return await _getAuth(url)
}

export const getuserapprovalReport = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/report/user-approval'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/report/user-approval/'+id 
    }
    return await _getAuth(url)
}

export const getCouponScanReport = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/report/coupon-scan'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/report/coupon-scan/'+id 
    }
    return await _getAuth(url)
}

export const getRedemptionReport = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/report/user-withdrawal'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/report/user-withdrawal/'+id 
    }
    return await _getAuth(url)
}

export const getSKUReport = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/report/sku-redeemption'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/report/sku-redeemption/'+id 
    }
    return await _getAuth(url)
}

export const getTDSReport = async ({id, params}:{id?:any , params?:any}) => {
    let url = API_INITIAL + '/admin/report/tds-deduction'
    if(params){
        let str = convertObjToQueryStr(params);
        url = url + str
    }
    if(id){
        url = API_INITIAL + '/admin/report/tds-deduction/'+id 
    }
    return await _getAuth(url)
}