import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { redirectCoupon } from "../Apis";
import QRCode from "react-qr-code";
import { AdminLogo, BZLogo } from "../Assets/Images";

type Props = {};

let style = {
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
};

const RedirectCoupon = (props: Props) => {
  const [couponvalue, setCouponvalue] = useState<any>({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qrValue = queryParams.get("qr");

  const getCoupon = async () => {
    const params = { coupon_code: qrValue };
    await redirectCoupon(params)
      .then((res: any) => {
        setCouponvalue(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCoupon();
  }, []);

  return (
    <div
      className="text-center d-flex justify-content-center"
      style={{ marginTop: "0", marginBottom: "0" }}
    >
      <div className="col-sm-4 text-center mt-5 mb-5" style={style}>
        <div className="p-3">
          {/* <img src={AdminLogo} /> */}
          <img src={BZLogo} style={{width : "90px", height : "90px"}} />
        </div>
        <h4>SCAN QR CODE</h4>
        <p style={{ padding: "0px 45px" }}>
          Get reward by scanning the QR Code with the camera
        </p>
        {
          couponvalue?.data?.qr &&
          <>
          <img
          style={{ width: 350, height: 350 }}
          src={couponvalue?.data?.qr}
        /> 
        <h5 style={{marginTop : "-10px"}} className="mb-4">{qrValue}</h5>
        </>
        || 
        <h4 className="mt-5 mb-5">{couponvalue?.message}</h4>
        }
        <div style={{backgroundColor : "#02232c", padding : "15px"}}>
          <span style={{color : "#fff"}}>Powerd By : </span>
          <img src={AdminLogo} />
        </div>
      </div>
    </div>
  );
};

export default RedirectCoupon;
