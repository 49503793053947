  import React from "react";
import ReactDOM, { Root } from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./Redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./Contexts/AuthContext";
import { createTheme, ThemeProvider } from "@mui/material";

const root: Root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const fontFamily = "Poppins"
const labelColor = "rgba(0, 0, 0, 0.6)"

const theme = createTheme(
  { 
  palette: {
    primary: {
      main: '#02232C',
    },
  },
  typography: {
    fontFamily: fontFamily,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            fontSize:"0.875rem",
            fontWeight:"500"
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: fontFamily,
          fontSize: '14px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select:{
          padding:"7px 14px",
          fontSize:"0.875rem",
          fontWeight:"500",
          color:labelColor,
          opacity:"1",
        },
        icon: {
          color: 'rgb(34, 43, 66)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '20px', 
          "&::placeholder": {
            fontSize:"0.875rem",
            fontWeight:"500",
            lineHeight:"1",
            color:labelColor,
            opacity:"1"
          },
        },
        root:{
          "& ::-webkit-datetime-edit-month-field": { textTransform: "uppercase" },
          "& ::-webkit-datetime-edit-day-field": {   textTransform: "uppercase" },
          "& ::-webkit-datetime-edit-year-field": {  textTransform: "uppercase" },
          "& input[type='file']" : {
            padding:"7px 10px 12px",
            "&::file-selector-button":{
              height:"25px",
              fontSize:"14px"
            }
          },
          "& input[type='date']" : {
            color: labelColor,
            fontFamily: fontFamily,
            fontWeight: "500",
            fontSize: "14px",
          }
        }
      },
    },
    MuiButton:{
      styleOverrides: {
        root:{
          borderRadius: '6px',
          padding: '8px 24px',
        }
     }
    }
  },
})

root.render(
  <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <App />
          <ToastContainer />
        </AuthProvider>
      </ThemeProvider>
  </Provider>
);
