import { ReactNode, Context } from "react";
import React, { createContext, useEffect, useReducer } from "react";
import { redirect, useLocation } from "react-router-dom";
import axios from "../axios";
import { signIn, getProfile, signOut } from "../Apis";
import { upadateSubAdmin } from "../Apis/SuperAdminServices";

interface AuthContextProps {
  isAuthenticated: boolean | null;
  isInitialized: boolean;
  user: any;
  login: (email?: string, password?: string) => Promise<void>;
  logout: () => Promise<void>;
  updateProfile: (payload?: any, id?: string) => Promise<void>;
}

const initialState: AuthContextProps = {
  isAuthenticated: null,
  isInitialized: false,
  user: null,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
};

const isValidToken = (token: string) => {
  if (!token) {
    return false;
  }

  return true;
};

const setSession = (token: string | null) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `${token}`;
    localStorage.setItem("accessToken", token);
  } else {
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "PROFILE": {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext: Context<AuthContextProps> = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
});


export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  
  const login = async (credential: string, pass: string) => {
    const res = await signIn({ credential: credential, password: pass })
    if(res.status){
      const { token, refreshToken } = res.data;
      setSession(token);
      localStorage.setItem("refreshToken", refreshToken);
      dispatch({
        type: "LOGIN",
        payload: {
          user: res.data,
        },
      });
    }
    return res
  };

  const updateProfile = async (payload: any, id: string) => {
    const response: any = await upadateSubAdmin(payload, id);
    if (response?.status) {
      dispatch({
        type: "PROFILE",
        payload: {
          user: response.data,
        },
      });
    }
    return response;
  };

  const logout = async () => {
    const response: any = await signOut();
    setSession(null);
    dispatch({ type: "LOGOUT" });
    redirect("/");
    return response;
  };

  useEffect(() => {
    (async () => {
      try {
        const token = window.localStorage.getItem("accessToken");
        if (token && isValidToken(token)) {
          setSession(token);
          const resData: any = await getProfile();
          if (resData?.data) {
            let user = resData?.data;
            dispatch({
              type: "INIT",
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          } else if (resData?.code === 401) {
            dispatch({
              type: "INIT",
              payload: {
                isAuthenticated: false,
              },
            });
          } else {
            dispatch({
              type: "INIT",
              payload: {
                isAuthenticated: false,
              },
            });
          }
          //   });
        } else {
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (e) {
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
