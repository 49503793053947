import axiosCall from "../axios";


const authToken : any = () => localStorage.getItem('accessToken')
// const API_INITIAL : any = process.env.REACT_APP_API_URL



export const _get = (url:string,params: any = {}) =>
  axiosCall.get(url,params);

export const _post = (url:string,data: any = {}, token: any = "") =>
  axiosCall.post(url,data, token);

export const _put = (url:string,data: any = {}) => axiosCall.put(url,data);

export const _patch = (url: string, data: any = {}) =>
  axiosCall.patch(url, data);

export const _delete = (url:string,data: any = {}) =>
  axiosCall.delete(url,data);


export const _getAuth = async (url:string,data: any = {}) => {
  return await axiosCall.get(url,{ headers: { "Authorization": `${authToken()}` } }).then((response) => {
    return response
  }).catch((err) => {
    return err
  })
}

export const _postAuth = async (url:string,data: any = {}) => {
  return await axiosCall.post(url,data, { headers: { "Authorization": `${authToken()}` } }).then((response) => {
    return response
  }).catch((err) => {
    return err
  })
}

export const _putAuth = async (url:string,data: any = {}) => {
  return await axiosCall.put(url,data, { headers: { "Authorization": `${authToken()}` } }).then((response) => {
    return response
  }).catch((err) => {
    return err
  })
}

export const convertObjToQueryStr = (params:any) => {
  const objStr = "?" + Object.keys(params).map(key => {
    return `${key}=${encodeURIComponent(params[key])}`
  }).join('&')
  return objStr
}